import { environment } from './../../environments/environment';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RouteReuseStrategy } from '@angular/router';
import { CacheRouteReuseStrategy } from '../strategy/CacheRouteReuseStrategy';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(
    private http: HttpClient,
    private routeReuseStrategy: RouteReuseStrategy,
    private storageService: StorageService,
    private message: NzMessageService
  ) {}

  doLogin(cridential: any) {
    return this.http
      .post(`${environment.API_URL}/signin/client`, cridential)
      .pipe(
        catchError((error: any) => {
          if (error.code === 401) {
            this.message.create('error', `Bad Cridential`);
          }
          return throwError(error.error);
        })
      );
  }

  doLogout() {
    (this
      .routeReuseStrategy as CacheRouteReuseStrategy).storedRouteHandles.clear();
    this.storageService.removeIdentity();
  }

  changePassword(data: any) {
    return this.http.put(`${environment.API_URL}/client/change-password`, data);
  }
}
