<nz-drawer
  [nzClosable]="false"
  [nzVisible]="isShowPropertyDetail"
  (nzOnClose)="close()"
  [nzWidth]="550"
  nzPlacement="right"
>
  <nz-spin [nzSpinning]="isDetailLoading">
    <div *ngIf="property.orderImage && property.orderImage.length > 0">
      <nz-card nzBordered="false" [nzCover]="coverTemplate" id="imageOrder">
        <nz-card-tab>
          <nz-tabset
            nzSize="large"
            [(nzSelectedIndex)]="nzSelectedIndex"
            (nzSelectedIndexChange)="onSelectedIndexChange($event)"
          >
            <nz-tab nzTitle="Property"> </nz-tab>
          </nz-tabset>
        </nz-card-tab>
      </nz-card>

      <ng-template #coverTemplate>
        <div style="position: relative">
          <nz-carousel
            nzAutoPlay
            style="height: 300px; width: 100%"
            [nzEnableSwipe]="false"
            (nzAfterChange)="carouselChange($event)"
          >
            <img
              style="
                max-width: 100%;
                max-height: 100%;
                display: block;
                object-fit: cover;
                cursor: pointer;
              "
              nz-carousel-content
              *ngFor="let image of imageArr[nzSelectedIndex]"
              [src]="image"
            />
          </nz-carousel>
          <i class="alt" nz-icon nzType="arrows-alt" nzTheme="outline"></i>
        </div>
      </ng-template>
    </div>

    <div *ngIf="!property.orderImage || property.orderImage.length === 0">
      <nz-carousel nzAutoPlay style="height: 300px; width: 100%">
        <img
          alt="property image"
          nz-carousel-content
          *ngFor="let image of propertyDetail.imagesTemp"
          [src]="image"
          style="width: 100%; display: block; object-fit: cover"
        />
      </nz-carousel>
    </div>

    <div style="padding: 24px">
      <div nz-row>
        <div nz-col nzSpan="18">
          <h3 style="margin-bottom: 4px">{{ propertyDetail.propertyName }}</h3>
          {{ propertyDetail.address }}
        </div>
        <div nz-col nzSpan="6">
          <nz-tag [nzColor]="'#f50'">
            {{
              propertyDetail.typeId === 1
                ? 'Commercial'
                : propertyDetail.typeId === 2
                ? 'Residential'
                : propertyDetail.typeId === 3
                ? 'Hotel'
                : ''
            }}
          </nz-tag>
        </div>
      </div>

      <div nz-row style="text-align: center">
        <div nz-col nzSpan="8" style="padding-top: 30px">
          <span style="color: red; font-weight: bold">{{
            propertyDetail.towerNumber
          }}</span
          ><br />
          <b>Tower</b>
        </div>
        <div nz-col nzSpan="8" style="padding-top: 30px">
          <span style="color: red; font-weight: bold">{{
            propertyDetail.liftNumber
          }}</span
          ><br />
          <b>Lift</b>
        </div>
        <div
          nz-col
          nzSpan="8"
          *ngIf="propertyDetail.pointScreen > 0"
          style="padding-top: 30px"
        >
          <span style="color: red; font-weight: bold">{{
            propertyDetail.pointScreen
          }}</span
          ><br />
          <b>Screen</b>
        </div>

        <div
          nz-col
          nzSpan="8"
          *ngIf="propertyDetail.pointStandingScreen > 0"
          style="padding-top: 30px"
        >
          <span style="color: red; font-weight: bold">{{
            propertyDetail.pointStandingScreen
          }}</span
          ><br />
          <b>Std. Screen</b>
        </div>
        <div
          nz-col
          nzSpan="8"
          *ngIf="propertyDetail.pointFramePoster > 0"
          style="padding-top: 30px"
        >
          <span style="color: red; font-weight: bold">{{
            propertyDetail.pointFramePoster
          }}</span
          ><br />
          <b>Frame Poster</b>
        </div>
        <!-- <div
          nz-col
          nzSpan="8"
          *ngIf="propertyDetail.pointMagicPoster > 0"
          style="padding-top: 30px"
        >
          <span style="color: red; font-weight: bold">{{
            propertyDetail.pointMagicPoster
          }}</span
          ><br />
          <b>Magic Poster</b>
        </div> -->
      </div>

      <div
        style="text-align: right; margin-top: 10px; color: blue"
        *ngIf="showDetail"
      >
        <span (click)="showPoints()" style="cursor: pointer">Detail >></span>
      </div>
    </div>

    <div style="background: #f7f7f7; padding-top: 10px; width: 100%">
      <div nz-row>
        <div nz-col [nzSpan]="24">
          <nz-card nzTitle="Basic Info">
            <div nz-row class="text-spaces">
              <div nz-col [nzSpan]="16">
                <span class="text-fonts">Property age : </span>
              </div>
              <div nz-col [nzSpan]="8">
                <span>{{ property.propertyAge }}</span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col [nzSpan]="16">
                <span class="text-fonts">Occupancy rate : </span>
              </div>
              <div nz-col [nzSpan]="8">
                <span>{{ propertyDetail.occupancyRate }} </span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Lowest floor : </span>
              </div>
              <div nz-col nzSpan="8">
                <span>{{ propertyDetail.lowestFloor }}</span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Higest floor : </span>
              </div>
              <div nz-col nzSpan="8">
                <span>{{ propertyDetail.highestFloor }} </span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Maintenance Fee(Rs./Sq.Ft) : </span>
              </div>
              <div nz-col nzSpan="8">
                <span>{{ propertyDetail.housePriceRange }} </span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Parking spaces : </span>
              </div>
              <div nz-col nzSpan="8">
                <span>{{ propertyDetail.parkingSpace }} </span>
              </div>
            </div>

            <div nz-row class="text-spaces" *ngIf="propertyDetail.typeId === 2">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Flat price range : </span>
              </div>
              <div nz-col nzSpan="8">
                <span
                  >{{ propertyDetail.sellingPriceLow }} -
                  {{ propertyDetail.sellingPriceHigh }}</span
                >
              </div>
            </div>

            <div
              nz-row
              class="text-spaces"
              style="padding-bottom: 10px"
              *ngIf="propertyDetail.typeId === 1"
            >
              <div nz-col nzSpan="16">
                <span class="text-fonts">Average rent : </span>
              </div>
              <div nz-col nzSpan="8">
                <span
                  >{{ propertyDetail.rentalLow }} -
                  {{ propertyDetail.rentalHigh }}</span
                >
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </div>

    <div style="background: #f7f7f7; padding-top: 10px; width: 100%">
      <div nz-row>
        <div nz-col [nzSpan]="24">
          <nz-card>
            <div
              nz-row
              class="text-spaces"
              *ngIf="propertyDetail.typeId === 1 || propertyDetail.typeId === 2"
            >
              <div nz-col nzSpan="16">
                <span class="text-fonts">
                  {{
                    propertyDetail.typeId === 1
                      ? 'Companies'
                      : 'Staying/Households'
                  }}
                </span>
              </div>
              <div nz-col nzSpan="8">
                <span
                  >{{
                    propertyDetail.typeId === 1
                      ? propertyDetail.companyNumber
                      : propertyDetail.flatNumber
                  }}
                </span>
              </div>
            </div>

            <div nz-row class="text-spaces">
              <div nz-col nzSpan="16">
                <span class="text-fonts">Population : </span>
              </div>
              <div nz-col nzSpan="8">
                <span>{{ propertyDetail.population }} </span>
              </div>
            </div>
          </nz-card>
        </div>
      </div>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Gender Ratio</span></h3>
        <div
          echarts
          [options]="genderChartOption"
          [merge]="genderChartOption"
          autoResize="true"
          style="height: 170px"
        ></div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Educational Distribution</span></h3>
        <div>
          <div
            echarts
            [options]="educationalChartOption"
            [merge]="educationalChartOption"
            autoResize="true"
            style="height: 170px"
          ></div>
        </div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Age Distribution(in %)</span></h3>
        <div>
          <div
            echarts
            [options]="ageChartOption"
            [merge]="ageChartOption"
            autoResize="true"
            style="height: 400px"
          ></div>
        </div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Income Level Distribution(in %)</span></h3>
        <div
          echarts
          [options]="incomeChartOption"
          [merge]="incomeChartOption"
          autoResize="true"
          style="height: 250px"
        ></div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3>
          <span class="text-lebal">Consumption Level Distribution(in %)</span>
        </h3>
        <div
          echarts
          [options]="consumptionChartOption"
          [merge]="consumptionChartOption"
          autoResize="true"
          style="height: 250px"
        ></div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Marriage percentage</span></h3>
        <div
          echarts
          [options]="marriageChartOption"
          [merge]="marriageChartOption"
          autoResize="true"
          style="height: 250px"
        ></div>
      </nz-card>
    </div>

    <div class="card">
      <nz-card>
        <h3><span class="text-lebal">Percentage of private vehicle</span></h3>
        <div>&nbsp;</div>
        <div
          echarts
          [options]="carChartOption"
          [merge]="carChartOption"
          autoResize="true"
          style="height: 250px"
        ></div>
      </nz-card>
    </div>
  </nz-spin>
</nz-drawer>
