import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router';

export class CacheRouteReuseStrategy implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetriveCache = {
    // 'order/list': false,
    // resources: false,
    // cart: false,
    home: true,
  };

  // If returns true then the store method will be invoked
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const name = this.getConfiguredUrl(route);
    if (this.allowRetriveCache.hasOwnProperty(name)) {
      return true;
    }
    return false;
  }

  // This method is invoked only if the shouldDetach returns true
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getConfiguredUrl(route), handle);
  }

  // If this method returns true then retrieve method will be called
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const name = this.getConfiguredUrl(route);

    // console.log('shouldAttach: ' + name + ' ,' + this.allowRetriveCache[name]);
    // console.log(route);
    if (this.allowRetriveCache[name]) {
      return this.storedRouteHandles.has(this.getConfiguredUrl(route));
    }

    if (this.getConfiguredUrl(route) === 'profile') {
      this.allowRetriveCache.home = false;
    } else {
      this.allowRetriveCache.home = true;
    }

    return false;
  }

  // This method is called if shouldAttach returns true
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(
      this.getConfiguredUrl(route)
    ) as DetachedRouteHandle;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // if (
    //   this.getConfiguredUrl(future) !== '' &&
    //   this.getConfiguredUrl(curr) !== ''
    // ) {
    //   console.log('future: ' + this.getConfiguredUrl(future));
    //   console.log('curr: ' + this.getConfiguredUrl(curr));
    // }

    // if (this.getComponentName(future) === 'OrderDetailComponent' && this.getComponentName(curr) === 'OrderListComponent') {
    //   this.allowRetriveCache.OrderListComponent = true;
    // } else {
    //   this.allowRetriveCache.OrderListComponent = false;
    // }
    return future.routeConfig === curr.routeConfig;
  }

  private getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    // console.log(route.pathFromRoot);
    return route.pathFromRoot
      .filter((v) => v.routeConfig && v.routeConfig.path !== '')
      .map((v) => v.routeConfig.path)
      .join('/');
  }

  // private getResolvedUrl(route: ActivatedRouteSnapshot): string {
  //   return route.pathFromRoot
  //       .map(v => v.url.map(segment => segment.toString()).join('/'))
  //       .join('/');
  // }
}
