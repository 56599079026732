<nz-tabset (nzSelectedIndexChange)="tabChange($event)">
  <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab"> </nz-tab>
</nz-tabset>

<nz-table
  #pointsTable
  [nzLoading]="loading"
  [nzData]="pointData"
  [(nzPageIndex)]="pageNo"
  nzShowSizeChanger
  [nzShowTotal]="rangeTemplate"
>
  <thead>
    <tr>
      <th
        nzShowCheckbox
        [nzChecked]="isCheckedAll"
        [nzDisabled]="isPointDisabled"
        [nzIndeterminate]="isIndeterminate"
        (nzCheckedChange)="masterToggle($event)"
      ></th>
      <th>Location</th>
      <th>Code</th>
      <th>Product Type</th>
      <th>Product size</th>
      <th>Is Available</th>
      <th>Remark</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let point of pointsTable.data">
      <td
        nzShowCheckbox
        [(nzChecked)]="point.isChecked"
        (nzCheckedChange)="onPointChecked(point, $event)"
        [nzDisabled]="point.isDisabled"
      ></td>
      <td>{{ point.location }}</td>
      <td>{{ point.code }}</td>
      <td>{{ point.productType.type }}</td>
      <td>{{ point.screenSize }}</td>
      <td>
        {{ point.status === 'COMPLETED' ? 'Available' : 'Not available' }}
      </td>
      <td>{{ point.remark }}</td>
    </tr>
  </tbody>
</nz-table>

<ng-template #rangeTemplate let-range="range" let-total>
  {{ range[0] }}-{{ range[1] }} of {{ total }} items
</ng-template>
